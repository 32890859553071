.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vault {
  position: relative;
  z-index: 9999 !important;
  padding: 16px 12px;
  width: 700px;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
