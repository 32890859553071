.paper {
  margin: 4px;
  padding: 8px 0;
  width: auto;
  overflow: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
}

.buttons {
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
}

.table {
  margin: 12px 0;
  max-height: 420px;
  overflow: auto;
  background-color: white;
}

.table td {
  padding: 8px 0.8em;
  text-align: center !important;
}

.table strong {
  font-size: 18px;
  font-weight: 500;
}

.table small {
  font-size: 12px;
  font-weight: 300;
}

.table tbody tr td:first-child {
  background-color: #7c7c7c;
  color: white !important;
  text-align: left !important;
  font-size: 18px;
}

.table th {
  background-color: #696969;
  color: white;
  padding: 10px 1em;
  text-align: center !important;
}

.table tfoot tr {
  background-color: #7c7c7c;
}

.table tfoot tr td,
.table tfoot tr strong,
.table th strong {
  color: white !important;
}

.table th:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

@media (max-width: 768px) {
  .paper {
    margin: 4px 0;
  }
  .table td {
    padding: 1em 0;
  }
}
