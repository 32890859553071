* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
}

strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #515151;
}

a {
  text-decoration: none;
  color: inherit;
}

table td a {
  text-decoration: underline;
}

body button {
  color: #696969 !important;
}

body {
  margin: 0;
  background-color: #f5f6fa;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
