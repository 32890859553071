.paper {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.table {
  margin: 12px 0;
  /* max-height: 420px; */
  overflow: auto;
  background-color: white;
}

.table td {
  padding: 8px 1em;
  text-align: center !important;
}

.table th {
  background-color: #696969;
  color: white;
  padding: 0.3em 1em;
  text-align: center;
}

.table th:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.filters th {
  position: relative;
  z-index: 1;
  background-color: #fafafa;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
}
