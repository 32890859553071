.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 24px;
}

.input {
  width: 350px;
  margin: 12px 9px !important;
}

.red {
  color: #e00000;
  font-weight: 600;
}

.green {
  color: #128b12;
  font-weight: 600;
}

.blue {
  color: #1976d2;
  font-weight: 600;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .MuiContainer-root {
    padding: 0 !important;
  }
}

.rdrDefinedRangesWrapper {
  display: none !important;
}
