.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  background-color: white;
  font-weight: 500;
  margin: 10px 0;
  height: 55px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.header img {
  height: 50px;
  object-fit: contain;
}

.header span,
.logo {
  color: #696969;
}

.logo {
  padding: 0 4px;
}

.navItem,
.activeNavItem {
  display: table-cell;
  position: relative;
  margin: 0 10px;
  color: #696969;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 10px 14px;
}

.navItem:after,
.activeNavItem:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #696969;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navItem:hover:after {
  width: 100%;
  left: 0;
}

.activeNavItem:after {
  width: 100%;
  left: 0;
}

.greeting {
  margin-right: 18px;
}
.header {
  margin: 0;
}
.navItem {
  display: none;
}
.logo {
  margin-right: 14px;
}
section {
  display: flex;
  align-items: center;
  position: sticky;
  top: 55px;
  z-index: 980;
  margin: 0 10px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  transition: 0.2s linear;
  box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
}
section .navItem {
  display: table-cell;
}

@media (max-width: 1000px) {
  section {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .greeting {
    display: none;
  }
}
