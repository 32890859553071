.heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin-left: 8px;
}

.blockList {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background-color: #fcb9b9c9;
}

.blockList>.MuiAlert-message {
  padding: 0 !important
}