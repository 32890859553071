.accessBtn {
  cursor: pointer;
  margin-right: 8px;
  color: #515151;
  font-size: 21px;
  transition: all 0.2s;
}

.accessBtn:hover {
  transform: rotate(30deg);
}
