.table {
  margin: 12px 0;
  overflow: auto;
  background-color: white;
}

.table td {
  padding: 2px 1em;
  text-align: center !important;
}

.table.disabled td {
  background-color: #ebebeb;
}

.table th {
  background-color: #696969;
  color: white;
  padding: 0.4em 0.7em;
  text-align: center;
}

.table th:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table a {
  text-decoration: underline;
  font-weight: 600;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}

.createBot {
  cursor: pointer;
  transition: all 0.2s;
}

.createBot:hover {
  color: #696969;
}

.paper {
  margin: 4px;
  padding: 8px 0;
  max-height: 100vh;
  overflow: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.table .tableHeadCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  position: relative;
  z-index: 9999 !important;
  padding: 20px 4px;
  width: 1000px;
}

.rangeButtons {
  display: flex;
  gap: 8px;
  padding: 0 10px;
}

@media (max-width: 1024px) {
  .graph {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .paper {
    margin: 4px 0;
  }
}

.profit {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  color: #515151;
}