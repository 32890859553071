.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
  overflow: auto;
}

.settings {
  position: relative;
  z-index: 9999 !important;
  padding: 16px 12px;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.inputLabel {
  position: static !important;
  transform: none !important;
  font-size: 12px !important;
}
