.burger {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.burger div {
  transition: all 0.3s linear;
  width: 100%;
  border-radius: 2px;
  height: 3px;
  background-color: #696969;
  transform-origin: 1px;
}
